import React, { Component } from "react";
import { Layout, BackTop } from "antd";
import Menu from "../components/Menu/index";
import ContentMain from "../components/contentMain/index";

import logo from ".././assets/img/logo_toubu.png";
import ditu from ".././assets/img/logo_shouye_dibu.png";
import weichat from ".././assets/img/img_dibu_guanfangweixin.png";
import store from "../store";
// import policeUrl from "../commons/隐私政策.pdf";
// import applicationUrl from "../commons/免责声明.pdf";
const { Header, Content, Footer } = Layout;
// // console.log(pdfUrl)
const styleImg = {
  width: "155px",
  height: "44px",
  marginTop: "10px",
};
class baseLayout extends Component {
  constructor(props) {
    super(props);

    this.state = store.getState();
    // 注意需要绑定 this
    this.handleStoreChange = this.handleStoreChange.bind(this);
    // 注册监听store，store变化后调用组件的handleStoreChange方法更新组件的state
    store.subscribe(this.handleStoreChange);
  }
  handleStoreChange() {
    this.setState(store.getState());
  }
  render() {
    const { children } = this.props;
    return (
      <Layout className="layout">
        <Header>
          <div className="header" style={this.state}>
            <div className="header-middle">
              <img src={logo} style={styleImg} />

              <Menu />
            </div>
          </div>
        </Header>
        <Content>
          <ContentMain />
        </Content>
       
        <BackTop visibilityHeight={200} style={{ right: 50 }} />
      </Layout>
    );
  }
}

export default baseLayout;
