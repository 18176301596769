import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import LoadableComponent from '../../utils/LoadableComponent'
import PrivateRoute from '../PrivateRoute'

const Home = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/home/index'))  //参数一定要是函数，否则不会懒加载，只会代码拆分
const Product = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/product/index'))
const ServiceCase = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/serviceCase/index'))
const CoreTechnique = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/coreTechnique/index'))
const Cooperation = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/cooperation/index'))
const AboutUs = LoadableComponent(()=>import( /* webpackPrefetch: true */'../../page/aboutUs/index'))

class ContentMain extends React.Component {
  render () {
    return (
        <Switch>
          <PrivateRoute exact path='/home' component={Home}/>
          <PrivateRoute exact path='/product' component={Product}/>
          <PrivateRoute exact path='/product/0' component={Product}/>
          <PrivateRoute exact path='/product/1' component={Product}/>
          <PrivateRoute exact path='/product/2' component={Product}/>
          <PrivateRoute exact path='/product/3' component={Product}/>
          <PrivateRoute exact path='/product/4' component={Product}/>
          <PrivateRoute exact path='/serviceCase' component={ServiceCase}/>

          <PrivateRoute exact path='/coreTechnique' component={CoreTechnique}/>
          <PrivateRoute exact path='/coreTechnique/0' component={CoreTechnique}/>
          <PrivateRoute exact path='/coreTechnique/1' component={CoreTechnique}/>
          <PrivateRoute exact path='/coreTechnique/2' component={CoreTechnique}/>
          <PrivateRoute exact path='/coreTechnique/3' component={CoreTechnique}/>
          <PrivateRoute exact path='/coreTechnique/4' component={CoreTechnique}/>
          <PrivateRoute exact path='/cooperation' component={Cooperation}/>
          <PrivateRoute exact path='/aboutUs' component={AboutUs}/>
          <Redirect exact from='*' to='/home'/>
        </Switch>
    )
  }
}

export default withRouter(ContentMain)
