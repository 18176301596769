import './App.less';
import './style/index.less'
import React, {Component} from 'react';
import PrivateRoute from './components/PrivateRoute'
import {Route,Switch} from 'react-router-dom'
import Index from './layout/index'
import Layout from './layout'

function App() {
  return (
    <div className="App">
      <Layout>
      <Switch>
        <PrivateRoute path='/' component={Index}/>
      </Switch>
      </Layout>
    </div>
  );
}

export default App;
