import React from 'react'
import CustomMenu from "../CustomMenu/index";

const menus = [
  {
    title: '首页',
    icon: '',
    key: '/'
  },
  {
    title: '公司产品',
    icon: '',
    key: '/product',
    subs: [
      {key: '/product/3', title: '农村交通安全风险智能研判系统', icon: 'logo_gongsichanpin'},
      {key: '/product/0', title: '新橙慧安合成研判平台', icon: 'logo_gongsichanpin'},
      {key: '/product/1', title: '新橙追影特征目标识别追踪系统【平台】', icon: 'logo_gongsichanpin'},
      {key: '/product/2', title: '新橙追影-视频研判系统【单兵】', icon: 'logo_gongsichanpin'},
      {key: '/product/4', title: '新橙慧兴数字化实战工具', icon: 'logo_gongsichanpin'},
    ]
  },
  {
    title: '核心技术',
    icon: '',
    key: 'coreTechnique',
    subs: [
      {key: '/coreTechnique/0', title: '跨模态检索', icon: 'kuamotai'},
      {key: '/coreTechnique/1', title: 'ReID算法', icon: 'reidsousuo'},
      {key: '/coreTechnique/2', title: '视频碰撞', icon: 'shipinpengzhuang'},
      {key: '/coreTechnique/3', title: '人脸比对', icon: 'renlianbidui'},
      {key: '/coreTechnique/4', title: '图像增强', icon: 'tuxiangzengqiang'},
    ]
  },
  {
    title: '服务案例',
    icon: '',
    key: '/serviceCase',
    // subs: [
    //   {
    //     key: '/home/entry/form',
    //     title: '表单',
    //     icon: '',
    //     subs: [
    //       {key: '/home/entry/form/basic-form', title: '基础表单', icon: ''},
    //       {key: '/home/entry/form/step-form', title: '分步表单', icon: ''}
    //     ]
    //   },
    //   {key: '/home/entry/upload', title: '上传', icon: ''},
    // ]
  },
  {
    title: '商务合作',
    icon: '',
    key: '/cooperation',
    // subs: [
    //   {key: '/home/display/carousel', title: '轮播图', icon: ''},
    //   {key: '/home/display/collapse', title: '折叠面板', icon: ''},
    //   {key: '/home/display/list', title: '列表', icon: ''},
    //   {key: '/home/display/table', title: '表格', icon: ''},
    //   {key: '/home/display/tabs', title: '标签页', icosn: '',},
    // ]
  },
  {
    title: '关于我们',
    icon: '',
    key: '/aboutUs',
    // subs: [
    //   {key: '/home/feedback/modal', title: '对话框', icon: '',},
    //   {key: '/home/feedback/notification', title: '通知提醒框', icon: ''},
    //   {key: '/home/feedback/spin', title: '加载中', icon: '',}
    // ]
  }
]


class SiderNav extends React.Component {
  render() {

    return (
      <div>
        <CustomMenu menus={menus}/>
      </div>
    )
  }
}

const styles = {
  logo: {
    height: '32px',
    background: 'rgba(255, 255, 255, .2)',
    margin: '16px'
  }
}

export default SiderNav
