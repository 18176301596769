const defaultState = {
    background:'transparent',
    borderBottom:'1px solid rgba(255,255,255,0.2)'
}
export default (state = defaultState,action)=>{
    if(action.type ==false){
        const newState = JSON.parse(JSON.stringify(state));
        newState.background='transparent'
        return newState;
    }
    else {
        const newState = JSON.parse(JSON.stringify(state));
        newState.background='transparent'
        return newState;
    }
    return state;
}
